.app-layout{
    display: flex;
    position: relative;
}

.layout-left{
    transition: 0.5s ease-in-out;
    background-color: var(--white);
}

.layout-right{
    background-color: var(--lightgrey);
    min-height: 94vh;
    width: 100%;
}


.sidecar-right-arrow{
    display: none;
    position: fixed;
    z-index: 1000;
    top: 3px;
    left: -10px;
    background-color: #c4c6d0;
    padding: 5px;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    align-items: center;
    transition: 0.5s ease-in-out;
}

.layout-right-main{
    padding: 20px;
    overflow-y: scroll;
    margin-top: 40px;
    height: 93vh;
}

.layout-right-main::-webkit-scrollbar {
    width: 10px;
  }
  
  .layout-right-main::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 14px;
  }
  
  .layout-right-main::-webkit-scrollbar-thumb {
    background: #9d9d9d;
    border-radius: 14px;
  }
  
  @supports not selector(::-webkit-scrollbar) {
    .layout-right-main {
        scrollbar-color: #9d9d9d
                       #e9e9e9;
    }
  }

@media screen and (max-width: 728px) {
    .layout-left{
        position: absolute;
        left: -230px;
        top: 0px;
        z-index: 100;
    }
    .layout-right-main{
        padding: 10px;
    }
    .sidecar-right-arrow{
        display: flex;
    }
}