@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&display=swap');

*{
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

:root{
  --darkviolet2: #604BE8; 
  --darkviolet: #4c33ee;
  --violet: #604BE8;
  --lightViolet: #f3f6ff;
  --lightViolet2: #f1f3ff;
  --lightViolet3:rgb(195, 185, 255);
  --lightviolet4:rgb(219 213 255);
  --white: #ffffff;
  --lightwhite: #f3f3f3;
  --lightwhite2: #ffffff;
  --lightgrey: #f4f7ff;
  --lightgrey2: #C3CDE4;
  --lightgrey3: #626573;
  --black: #302D3D;
  --orange: #ff9f01;
  --green: #03d7a0;
  --lightgreen: #c2fff1;
  --darkgreen: #06ca96;
  --yellow: #FDD36D;
  --red: #FF7A7E;
  --darkred:rgb(249, 71, 77);
  --blue: #4B76E6;
  --darkgrey: #8b8f9c;
  --darkgrey2: #585858;
}

.App{
  user-select: none
}