.open-invoice{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 20px;
    flex-direction: column;
    gap: 20px;
    background-color: var(--lightViolet);
    min-height: 100vh;
}

.open-invoice-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
}

.open-invoice-footer-button{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.open-invoice-footer-button>button{
    color: var(--violet);
    background-color: var(--lightViolet);
    padding: 5px 10px;
    font-size: 13.5px;
    font-weight: 500;
    border-radius: 5px;
    width: 100px;
    border: 1px solid var(--darkviolet);
}

.open-invoice-footer-button>button:hover{
    background-color: var(--violet);
    color: var(--lightwhite2);
}
.open-invoice-footer-button>a:hover{
    background-color: var(--violet);
    color: var(--lightwhite2);
}

.open-invoice-footer-button>a{
    text-decoration: none;
    color: var(--violet);
    background-color: var(--lightViolet);
    padding: 5px 10px;
    font-size: 13.5px;
    font-weight: 500;
    border-radius: 5px;
    width: 150px;
    border: 1px solid var(--darkviolet);
    text-align: center;
}

.open-invoice-footer>a{
    text-decoration: none;
    color: var(--violet);
}

.open-invoice-footer>a:hover{
    color: var(--darkviolet);
    text-decoration: underline;
}