.right-page-middle{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.right-page-middle-category{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding: 20px 12px;
}
.right-page-middle-category>h2{
    font-size: 18px;
    font-weight: 400;
    color: var(--black);
}

.right-page-middle-category-items{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    
}

.right-page-middle-category-items>span{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--lightgrey3);
}

.right-page-middle-category-items>span>div{
    width: 150px;
}

.right-page-middle-category-items>span>div>label{
    font-size: 16px;
    color: var(--lightgrey3);
}

.right-page-middle-category-items>span>div>div>fieldset{
    border-color: var(--black);
}

.right-page-middle-category-items>span>div>div>input{
    font-size: 12px !important;
    height: 0.8em !important;
    padding: 14px;
    color: var(--lightgrey3);
}

.right-page-middle-category-items>span>div>div>div>button>svg{
    font-size: 14px;
    color: var(--lightgrey3);
}

.right-page-middle-category-items>li{
    list-style: none;
    background-color: var(--lightViolet);
    color: var(--violet);
    border: 1px solid var(--violet);
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 8px;
    font-weight: 200;
    font-size: 12px;
    cursor: pointer;
}

.right-page-middle-category-items>li:hover{
    background-color: var(--darkviolet);
    color: var(--lightwhite);
}

.right-page-middle-category-custom-dates{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px !important;
}

.right-page-middle-category-custom-dates>div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.right-page-middle-category-custom-dates>div>p{
    font-size: 16px;
    color: var(--lightgrey3);
}

.right-page-middle-category-custom-dates>div>input{
    width: 100%;
    padding: 5px;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid var(--lightgrey2);
    outline: none;
    color: var(--lightgrey3);
}

.right-page-middle-category-custom-dates>button{
    background-color: var(--violet);
    color: var(--lightViolet);
    border: 1px solid var(--violet);
    padding: 5px 16px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
}

.right-page-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
}

.right-page-content-viewBy{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 13px;
    width: 100%;
}

.right-page-content-viewBy>p{
    font-size: 12px;
    font-weight: 400;
    color: var(--lightgrey3);
}

.right-page-content-viewBy>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    gap: 2px;
    background-color: var(--lightwhite2);
    padding: 4px 8px;
    border: 1px solid var(--violet);
    width: 40px;
}

.right-page-content-viewBy>svg{
    font-size: 24px;
    color: var(--black);
    cursor: pointer;
}

.right-page-content-viewBy>div>svg{
    font-size: 14px;
    color: var(--darkgrey);
    cursor: pointer;
}

.right-page-content-viewBy>form>select{
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    color: var(--darkgrey);
    outline: none;
    border: none;
}

.right-page-content-viewBy>span{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    background-color: var(--lightwhite2);
    padding: 2px 10px;
    border-radius: 20px;
    box-shadow: 0px 1px 3px 0px var(--darkgrey);
    cursor: pointer;
}

.right-page-content-viewBy>span:hover{
    transform: translateY(1);
}

.right-page-content-viewBy>span>div{
    width: 20px;
    height: 20px;
    background-color: var(--darkgrey);
    border-radius: 100%;
}

.right-page-content-viewBy>span>p{
    font-size: 14px;
    color: var(--darkgrey);
}

.showing-result{
    color: var(--darkgrey);
}

.right-page-content-row{
    width: 100%;
    overflow-x: scroll;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 300px;
}

.right-page-content-row::-webkit-scrollbar {
    display: none;
  }
  
  .right-page-content-row{
    -ms-overflow-style: none;  
    scrollbar-width: none; 
  }

.right-page-content-row>h1{
    font-size: 20px;
    font-weight: 500;
}

.table{
    width: 100%;
    border-collapse: collapse;
    position: relative;
    margin-bottom: 50px;
}

.table>thead>tr{
    border-bottom: 1px solid var(--gray3);
    margin-bottom: 10px;
    background-color: var(--green);
    color: var(--lightwhite);
}

.table>thead>tr>th{
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    padding: 8px 12px;
}

.table>thead>tr>th:first-child{
    border-radius: 10px 0 0 10px;
}

.table>thead>tr>th:last-child{
    border-radius: 0px 10px 10px 0px;
    text-align: right;
    padding-right: 30px;
}

.table>tbody>tr>td:first-child{
    display: flex;
    gap: 3px;
    align-items: center;
}

.table>tbody>tr>td{
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    padding: 6px 12px;
    color: var(--lightgrey3);
}

.table>tbody>tr{
    border-bottom: 1px solid var(--lightgrey2);
}

.table>tbody>tr:focus{

    background-color: var(--lightViolet3);
}

.table>tbody>tr:hover{
    background-color: var(--lightViolet3);
    color: var(--lightwhite2);
    cursor: pointer;
}

.table>tbody>tr:hover > td {
    color: var(--lightwhite2);
}

.table>tbody>tr:hover > td:first-child{
    border-radius: 10px 0 0 10px;
}

.table>tbody>tr:hover > td:last-child{
    border-radius: 0px 10px 10px 0px;
}

.table>tbody>tr>td:last-child{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    text-align: right;
}

.right-page-content-grid{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
    min-height: 300px;
    padding-bottom: 30px;
}

.right-page-content-grid>h1{
    font-size: 20px;
    font-weight: 500;
}

.table-grid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    width: 130px;
    min-height: 120px;
    background-color: var(--lightwhite);
    border-radius: 6px;
}

.table-grid:hover{
    transform: translateY(-2px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    transition: transform 1s ease, box-shadow 0.3s ease;
}

.table-grid>h3{
    font-size: 15px;
    text-align: center;
    color: var(--darkgrey2);
}

.table-nonEmpty{
    background-color: var(--lightviolet4) !important;
}

.table-grid>p{
    font-size: 12px;
    font-weight: 500;
    color: var(--darkgrey2);
}

.table-grid>span{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.right-page-middle-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}

.right-page-middle-footer>nav>ul>li>button{
    border-color: var(--black);
    color: var(--black);
}
.right-page-middle-footer>nav>ul>li>button:hover{
    background-color:var(--lightViolet3) !important;
    color: var(--lightwhite);
    border-color: var(--violet);
}
.right-page-middle-footer>nav>ul>li>div{
    color: var(--black) !important;
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color:var(--lightViolet3) !important;
    color: var(--lightwhite);
    border-color: var(--violet);
}

.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked{
    color:var(--violet) !important;
}

.category-active-tab{
    background-color: var(--violet) !important;
    color: var(--lightwhite) !important;
}

@media screen and (max-width: 728px) {
    .right-page-middle>div{
        width: 100%;
        overflow-x: scroll;
        gap: 30px;
    }
    .right-page-middle-category-items{
        flex-wrap: nowrap;
        width: 100%;
    }
    .right-page-content-viewBy{
        justify-content: flex-start;
        overflow-x: scroll;
    }
    .right-page-content-row{
        justify-content: flex-start;
    }
    .right-page-content-grid{
        justify-content: flex-start;
    }
    .table-grid{
        /* width: 90px;
        height: 90px; */
    }
}