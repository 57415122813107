.table-loader{
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
}

.loaderRow{
    width: 100%;
    display: flex;
}
.loaderRow:nth-child(even){
    animation: 3s infinite gray-to-white ease-out ;
}

.loaderRow>td{
    height: 40px;
    width: 100%;
    flex: 1;
}

@keyframes gray-to-white {
    from {background-color: #f6f6f6}
    to {background-color: #e9e9e9}
  }

@keyframes white-to-gray {
    from {background-color: var(--white);}
    to {background-color: var(--gray5);}
  }

.loader {
  width: 22px;
  height: 22px;
  border: 3px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 