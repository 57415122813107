.MuiModal-root{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 10vh;
    overflow-y:scroll ;

}

.modal{
    width: 400px;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 60px;
    padding: 50px;
    border: none;
    outline: none;
    border-radius: 10px;
    background-color: var(--lightgrey);
    z-index: 10000;
}

.modal-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.modal-heading>p:nth-child(1){
    font-size: 22px;
    font-weight: 500;
    font-family: "Montserrat" , sans-serif;
    letter-spacing: 1.1px;
    color: var(--black);
}

.modal-heading>p:nth-child(2){
    font-size: 12px;
    font-weight: 400;
    font-family: "Montserrat" , sans-serif;
    color: var(--lightgrey3);
}

.modal-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.modal-content>form{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}

.modal-content>form>div{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    border-radius: 6px;
    padding: 5px 14px;
    cursor: pointer;
    position: relative;
}

.modal-content>form>div>p{
    font-size: 14px;
    font-weight: 400;
    color: var(--gray8);
}

.modal-content>form>div>input{
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 8px;
    width: 100%;
    color: var(--lightgrey3);
    font-size: 12px;
    outline-color: var(--lightgrey3);
    background-color: var(--lightwhite2);
}

.modal-content>form>div>select{
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 8px;
    width: 100%;
    color: var(--lightgrey3);
    font-size: 12px;
    outline-color: var(--lightgrey3);
    background-color: var(--lightwhite2);
}

.modal-button-group{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.success-button{
    width: 100%;
    padding: 15px 10px;
    background-color: var(--violet);
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    cursor: pointer;
    margin: 0 auto;
}

.success-button:hover{
    background-color: var(--darkviolet);
}

.danger-button{
    width: 100%;
    padding: 15px 10px;
    background-color: var(--red);
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    cursor: pointer;
    margin: 0 auto;
}

.danger-button:hover{
    background-color: var(--darkred);
}

.close-button{
    width: 100%;
    padding: 15px 10px;
    background-color: var(--darkgrey);
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    cursor: pointer;
    margin: 0 auto;
}


.close-button:hover{
    background-color: var(--darkgrey2);
}


@media screen and (max-width: 840px){
    .modal{
        padding: 20px 10px;
        width: 90vw;
    }
    .modal-heading>p{
        font-size: 16px;
    }
    .modal-content{
        width: 80%;
    }
    .modal-content>form{
        gap: 18px;
    }
}