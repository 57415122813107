.qr-order {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, var(--lightViolet2), var(--lightViolet));
    margin: 0;
    padding: 20px;
  }
  
  .qr-order-container {
    background-color: var(--white);
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    gap: 40px;
  }
  
  .qr-order-container-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .qr-order-container-header>img {
    max-width: 120px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .qr-order-container-header>h1 {
    font-size: 2em;
    color: var(--darkviolet);
    margin: 0;
    text-transform: capitalize;
  }
  
  .qr-order-container>h1 {
    font-size: 1.4em;
    color: var(--darkgrey2);
    margin-bottom: 20px;
    letter-spacing: 1px;
  }
  
  .qr-order-container-button {
    background-color: var(--darkviolet2);
    color: var(--white);
    font-size: 1.2em;
    padding: 15px 40px;
    border: none;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    text-decoration: none;
  }
  
  .qr-order-container-button svg {
    margin-right: 10px;
    font-size: 1.5em;
  }
  
  .qr-order-container-button:hover {
    background-color: var(--darkviolet);
    transform: translateY(-5px);
  }

  .qr-order-container>button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--red);
    color: var(--lightwhite2);
    border: none;
    outline: none;
    border-radius: 15px;
  }
  
  @media (max-width: 600px) {
    .qr-order-container {
      padding: 20px;
    }
  
    .qr-order-container-header h1 {
      font-size: 1.5em;
    }
  
    .qr-order h1 {
      font-size: 1.4em;
    }
  
    .qr-order-container-button {
      padding: 12px 30px;
      font-size: 1em;
    }
  }
  