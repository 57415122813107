.page-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--lightgrey3);
}

.page-heading-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 40%;
}

.page-heading-left>p:nth-child(1){
    font-size: 26px;
    font-weight: 600;
    color: var(--black);
}

.page-heading-left>p:nth-child(2){
    font-size: 11px;
    font-weight: 300;
    color: var(--lightgrey3);
}

.page-heading-right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    width: 55%;
}

.page-heading-searchBox{
    background-color: var(--lightwhite2);
    display: flex;
    justify-content: start;
    align-items: center;
    width: 60%;
    /* border: 1px solid var(--lightgrey3); */
    border-radius: 6px;
    padding: 5px 14px;
    cursor: pointer;
    position: relative;
}

.page-heading-searchBox>input{
    outline: none;
    border: none;
    width: 100%;
    color: var(--lightgrey3);
    font-size: 12px;
    background-color: inherit;
    outline-color: var(--lightgrey3);
}

.page-heading-searchBox>svg{
    color: var(--violet);
}

.page-heading-add-button{
    padding: 4px 20px;
    background-color: var(--violet);
    color: var(--white);
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    font-size: 12px;
    border: none;
    outline: none;
    border-radius: 0px;
    cursor: pointer;
    color: var(--lightwhite);
}

.page-heading-add-button:hover{
    background-color: var(--darkviolet);
}

.page-heading-add-button>p{
    font-weight: 500;
}

@media screen and (max-width:1000px) {
    .page-heading-add-button>p{
        display:none;
    }
    .page-heading-add-button{
        width: 50px;
        height: 50px;
        border-radius: 100%;
        justify-content: center;
    }
}

@media screen and (max-width:626px) {
    .page-heading{
        flex-direction: column;
        align-items: flex-start;
    }
    .page-heading-left{
        width: 100%;
    }
    .page-heading-right{
        width: 100%;
        justify-content: flex-start;
    }

    .page-heading-add-button{
        width: 40px;
        height: 40px;
    }

    .page-heading-add-button>svg{
        font-size: 18px;
    }

    .page-heading-searchBox>input{
        font-size: 12px;
    }
}