

@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .notfound-page{
    background-color: var(--lightwhite2);
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100vh;
  
  }
  
  .notfound-container {
    max-width: 600px;
    width: 100%;
    height: 482px;
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 1px 10px #ababab;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--lightViolet);
    color: var(--black);
    text-align: center;
    padding: 20px;
    animation: fadeIn 0.6s ease-in;
  }
  
  .notfound-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .digit {
    font-size: 8rem;
    font-weight: 900;
    color: rgb(106, 93, 195);
    animation: bounce 2s infinite;
  }
  
  .plate {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background-color: rgb(106, 93, 195);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 5s linear infinite;
  }
  
  .food-icon {
    font-size: 3rem;
  }
  
  .notfound-subtitle {
    font-size: 2rem;
    color:rgb(106, 93, 195) ;
    margin-bottom: 20px;
  }
  
  .notfound-text {
    font-size: 1.2rem;
    color: rgb(112, 111, 111);
    margin-bottom: 30px;
  }
  
  .notfound-link {
    font-size: 1.2rem;
    color: var(--white);
    background-color: var(--violet);
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .notfound-link:hover {
  
    transform: scale(1.05);
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  