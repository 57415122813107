.shop-container-main {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    align-items: center;
    height: 95vh;
    flex-direction: column;
    padding-top: 5vh;
  }

  .shop-container-header{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid var(--darkgrey);
  }

  .shop-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
  }

  .shop-box {
    width: 300px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
  }

  .shop-box:last-child{
    width: 7%;
    padding: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    background-color: var(--violet);
    color: var(--lightwhite2);
  }

  .shop-box:hover {
    transform: translateY(-10px);
  }
  
  .shop-image {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .shop-image>img{
    width: 100%;
    height: 100%;
}

  .shop-info {
    padding: 20px;
    text-align: center;
  }

  .shop-info>h2 {
    color: var(--violet);
    margin-bottom: 10px;
    font-size: 1.4em;
  }

  .shop-info>p {
    color: var(--darkgrey2);
    font-size: 1em;
    margin-bottom: 15px;
  }

  .shop-info>button {
    padding: 10px 20px;
    background-color: var(--violet);
    color: var(--white);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }

  .shop-info button:hover {
    background-color: var(--darkviolet) !important;
  }

  /* Edit Icon */
  .edit-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 10px;
    background-color: var(--violet);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .edit-icon:hover {
    background-color: var(--darkviolet);
  }

  .edit-icon>svg {
    fill: var(--white);
    width: 20px;
    height: 20px;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .shop-box {
      width: 80%;
    }
  }