.navbar{
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000;
}

.navbar-left{
    display: flex;
    justify-content: flex-start;
    height: 25px;
    gap: 10px;
    padding: 5px 0px 5px 30px;
}

.navbar-left>a{
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--lightgrey3);
    font-weight: 500;
    padding: 0px 20px;
    letter-spacing: 1.2px;
    border-radius: 8px;
    font-size: 14px;
}

.navbar-left>a:hover{
    background-color: var(--darkgreen);
    color: var(--lightwhite) !important;
}

.navbar-right{
    display: flex;
    gap: 15px;
    height: 28px;
    justify-content: center;
    align-items: center;
    padding: 5px 30px 5px 0px;
    position: relative;
}

.navbar-right>svg{
    font-size: 18px;
    color: var(--darkviolet2);
    cursor: pointer;
}

.navbar-right>a{
    text-decoration: none;
    color: var(--violet);
    background-color: var(--lightViolet);
    padding: 3px 10px;
    font-size: 13.5px;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid var(--darkviolet);
}

.navbar-right>a:hover{
    background-color: var(--violet);
    color: var(--lightwhite);
    border: 1px solid var(--lightwhite);
}

.navbar-right>select:hover{
    background-color: var(--violet);
    color: var(--lightwhite);
    border: 1px solid var(--lightwhite);
}

.navbar-right>select{
    outline: none;
    border: 1px solid var(--lightgrey3);
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 13.5px;
    font-weight: 500;
    color: var(--violet);
    background-color: var(--lightViolet);
}

.notify{
    position: absolute;
    top: 8px;
    left: 8px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--orange);;
}

.nav-active-tab{
    background-color: var(--green);
    color: var(--lightwhite) !important;
}

@media screen and (max-width: 728px) {
    .navbar{
        position: fixed;  
        z-index: 1000;  
    }
    .navbar-left{
        gap: 8px;
        padding-left: 15px;
    }
    .navbar-right{
        gap: 8px;
        padding-right: 15px;
    }
    .navbar-right>select{
        display: none;
    }
    .navbar-right>svg:nth-child(1){
        display: none;
    }
}